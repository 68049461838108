import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';

import {JwtModule} from './jwt';
import {environment} from '@Env/environment';
import {throwIfAlreadyLoaded} from './module-import-guard';


export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.host, environment.hostEmploiEtudiant],
        authScheme: 'JWT',
        headerName: 'Authorization',
      }
    })
  ],
  providers: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    console.log('CoreModule');
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
