import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ApplicationPortail } from "../common/services/application/application-portail.model";
import {ThemeModel, ThemesApplications} from "../common/services/theme/theme.model";
import {rolesPortail} from "../common/enums/roles-portail";
import {Individu, IndividuService} from "@Common/core/services";
import {SubSink} from "@Common/core/utils/subsink";
import {ApplicationPortailService} from "../common/services/application/application-portail.service";
import {ThemeService} from "../common/services/theme/theme.service";
import {FavoriService} from "../common/services/favori/favori.service";
import {FavoriModel} from "../common/services/favori/favori.model";

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  applications: ApplicationPortail[] = [];
  themes: ThemeModel[] = [];
  themesApplications: ThemesApplications[] = [];
  isAdmin = false;
  SubSink = new SubSink();
  individu: Individu;
  extraParams: Map<string, string> = new Map<string, string>();
  @Input() cssClass: string;

  constructor(private individuService: IndividuService,
              private appSvc: ApplicationPortailService,
              private themeSvc: ThemeService,
              private favSvc: FavoriService,) { }

  ngOnInit(): void {
    this.SubSink.sink = this.individuService.individu$.subscribe(_individu => {
      this.individu = _individu;
      if (this.individuService.hasRole(_individu, rolesPortail.admin)) {
        this.isAdmin = true;
      }
      this._getThemes();
    });
  }

  ngOnDestroy(): void {
    this.SubSink.unsubscribe();
  }

  getIcon(is_favorite: boolean): string {
    if(is_favorite) {
      return 'grade'
    }
    return 'star_border';
  }

  setFavorite(app: ApplicationPortail): void {
    this.extraParams.set('user', this.individu.uid);
    this.extraParams.set('application', app.id.toString());
    if(app.is_favorite){
      this.SubSink.sink = this.favSvc.listAllItems(this.extraParams).subscribe(_favori => {
        if(_favori.list.length > 0) {
          this.SubSink.sink = this.favSvc.delete(_favori.list[0]).subscribe(()=> {
            app.is_favorite = !app.is_favorite;
          });
        }
      });
    }else{
      const fav: FavoriModel = {application: app.id, user: this.individu.uid};
      this.SubSink.sink = this.favSvc.create(fav).subscribe(()=> {
        app.is_favorite = !app.is_favorite;
      });
    }

  }

  private _getApplications(): void{
    this.SubSink.sink = this.appSvc.listAllItems().subscribe((_data) => {
      this.applications = _data.list;
      this._initApplicationsThemes();
    });
  }

  private _getThemes(): void {
    this.SubSink.sink = this.themeSvc.listAllItems().subscribe((_themes) => {
      this.themes = _themes.list;
      this._getApplications();
    });
  }

  private _initApplicationsThemes(): void {
    const filteredApplications = this.isAdmin ?
      this.applications :
      this.applications.filter(application => {
        const theme = this.themes.find(theme => theme.id === application.theme);
        return theme && theme.is_public;
      });
    this.themesApplications = this.themes.map(theme => ({
      theme: theme,
      applications: filteredApplications.filter(application => theme.id === application.theme)
    }));
  }

}
