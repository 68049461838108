import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FavoriModel} from "../common/services/favori/favori.model";
import {ApplicationPortail} from "../common/services/application/application-portail.model";

@Component({
  selector: 'app-favoris',
  templateUrl: './favoris.component.html',
  styleUrls: ['./favoris.component.scss']
})
export class FavorisComponent implements OnInit {
  @Input() favoris: FavoriModel[];
  @Input() applications: ApplicationPortail[];
  @Input() cssClass: string;
  @Output() setFav = new EventEmitter<ApplicationPortail>();
  addTooltip = 'Ajouter des applications à vos favoris';
  favChoice = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleFavList(): void {
    this.favChoice = !this.favChoice;
  }

  saveFav(app: ApplicationPortail): void {
    this.setFav.emit(app);
  }

}
