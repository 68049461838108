import { Injectable } from '@angular/core';
import {DaoGeneric} from '@Services/base/daoService';
import {HttpClient} from '@angular/common/http';
import {environment} from '@Env/environment';
import {FavoriModel} from "./favori.model";

@Injectable({
  providedIn: 'root'
})
export class FavoriService extends DaoGeneric<FavoriModel> {
  private url = `${environment.baseUrl}/portail/favori/`;


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  getRootUrl(): string {
    return this.url;
  }

}
