<app-favoris [cssClass]="cssClass" [applications]="applications" (setFav)="setFavorite($event)"></app-favoris>
<ng-container *ngFor="let themeApp of themesApplications">
  <ng-container *ngIf="themeApp.applications.length > 0">
    <div class="{{cssClass}}">
      <mat-card>
        <mat-card-header>
          <mat-card-title>{{themeApp.theme.label}}</mat-card-title>
          <mat-card-subtitle>{{themeApp.theme.description}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <div class="{{cssClass}}__container">
            <ng-container *ngFor="let app of themeApp.applications">
              <a href="javascript:void(0)">
                <div class="app">
                  <div class="app__header"><mat-icon (click)="setFavorite(app)">{{getIcon(app.is_favorite)}}</mat-icon></div>
                  <div class="app__content"><mat-icon>{{(app.logo?app.logo:'apps')}}</mat-icon></div>
                  <div class="app__footer">
                    <a  *ngIf="app.is_active" href="{{app.path}}">
                      <button mat-raised-button>{{app.label}}</button>
                    </a>
                    <div class="app__footer__title">{{app.label}}</div>
                    <div class="app__footer__description">{{app.description}}</div>
                  </div>
                  <!--<div class="app__header"><mat-icon>star</mat-icon></div>-->
                </div>
              </a>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</ng-container>
